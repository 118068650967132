import * as React from 'react';
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {orange} from "@mui/material/colors";

export default function TocaSocialList() {
    const listStyle = {color: orange[700], textAlign: 'center', textColor: 'white', mb: '0.5em'};

    const secondaryTextStyle = {color: 'whitesmoke', mt: '0.1em'};

    return (
        <Box>
            <Typography variant={'h6'} sx={{color: orange[900], textAlign: 'center', textColor: 'white', mb: '0.5em'}}>
                Toca Social Reception Desk App
            </Typography>
            <Divider color={'white'}/>
            <List>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Designing Reception Desk App Interface" secondary=
                        "Designed and developed Reception Desk App for Toca using React, Material UI and TypeScript."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Developing the Back End" secondary=
                        "Designed and developed the back end for the Reception Desk App using Java, Spring Boot and PostgreSQL."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Build Automation" secondary=
                        "Automated the build process using AWS CodePipelines, CodeBuilds and GitHub Actions."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Disaster Recovery Planning" secondary=
                        "Devised and implemented disaster recovery plans for the Reception Desk App."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Infrastructure Management" secondary=
                        "Managed the infrastructure for the Reception Desk App using AWS, Terraform, and Docker."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
            </List>
        </Box>
    );
};
