import * as React from 'react';
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {orange} from "@mui/material/colors";

export default function MarksAndSpencerList() {

    const listStyle = {color: orange[700], textAlign: 'center', textColor: 'white', mb: '0.5em'};

    const secondaryTextStyle = {color: 'whitesmoke', mt: '0.1em'};

    return (
        <Box>
            <Typography variant={'h6'} sx={{color: orange[900], textAlign: 'center', textColor: 'white', mb: '0.5em'}}>
                Christmas Food To Order
            </Typography>
            <Divider color={'white'}/>
            <List>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Developing and Maintaining Test Automation Frameworks" secondary=
                        "Developed and maintained test automation frameworks using WebDriver IO, Cucumber, and JavaScript."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Design and Execution of Test Cases" secondary=
                        "Designed and executed test cases for functional, integration, and end-to-end testing."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Bug Reporting and Management" secondary=
                        "Reported and managed bugs using Jira and Confluence."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Collaboration with Development and Operations Teams" secondary=
                        "Collaborated with development and operations teams to ensure quality of the product."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Implementing CI/CD Pipelines" secondary=
                        "Implemented CI/CD pipelines using CircleCI."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
            </List>
        </Box>
    );
};
