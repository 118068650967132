import * as React from 'react';
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {orange} from "@mui/material/colors";

export default function LadbrokesCoralList() {

    const listStyle = {color: orange[700], textAlign: 'center', textColor: 'white', mb: '0.5em'};

    const secondaryTextStyle = {color: 'whitesmoke', mt: '0.1em'};

    return (
        <Box>
            <Typography variant={'h6'} sx={{color: orange[900], textAlign: 'center', textColor: 'white', mb: '0.5em'}}>
                Sportsbook and Gaming Applications
            </Typography>
            <Divider color={'white'}/>
            <List>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Developing and Maintaining Java Applications" secondary=
                        "Developed and maintained Java applications using Spring, Hibernate, and PostgreSQL."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Performance Tuning" secondary=
                        "Improved performance of Java applications by tuning JVM and database settings."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Testing and Debugging" secondary=
                        "Tested and debugged Java applications using JUnit, Mockito, MockMVC, and Spring Boot Test."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Updating and Enhancing Applications" secondary=
                        "Updated and enhanced Java applications to meet business requirements."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Database Management" secondary=
                        "Installed, configured, and managed PostgreSQL databases."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
            </List>
        </Box>
    );
};
