import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Card, Divider, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import {orange} from "@mui/material/colors";
import WebIcon from '@mui/icons-material/Web';


export default function StickyFooter() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                textAlign: 'center',
                backgroundColor: 'black',
                color: 'white',
            }}
            id={'footer-box'}
        >
            <Box
                component="footer"
                sx={{py: 3, px: 2, mt: 'auto'}}
                id={'sticky-footer'}
            >
                <Container component="main" maxWidth="xs" sx={{mt: '4em', mb: '2em'}}>
                    <Card sx={{
                        backgroundColor: orange[800],
                        mb: '20em',
                    }}
                    >
                        <Typography
                            variant={'h6'}
                            fontFamily={'monospace'}
                            fontWeight={'bold'}
                            letterSpacing={'.3rem'}
                            mb={'0.5em'}
                            padding={'0.2em'}
                        >
                            Get in touch
                        </Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <EmailIcon sx={{color: 'black'}}/>
                                    </ListItemIcon>
                                    <Link href={'mailto:folarin.odeyemi@gmail.com'} target={'_blank'} underline={'none'}>
                                        <ListItemText primary={'folarin.odeyemi@gmail.com'} sx={{color: 'black'}}/>
                                    </Link>
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Divider sx={{color: 'black'}}/>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <ContactPhoneIcon sx={{color: 'black'}}/>
                                </ListItemIcon>
                                <ListItemText primary={'+44 7464 473560'} sx={{color: 'black'}}/>
                            </ListItem>
                        </List>
                        <Divider sx={{color: 'black'}}/>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <WebIcon sx={{color: 'black'}}/>
                                    </ListItemIcon>
                                    <Link href={'https://openframeworks.limited'} target={'_blank'} underline={'none'}>
                                        <ListItemText primary={'openframeworks.limited'} sx={{color: 'black'}}/>
                                    </Link>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Card>
                </Container>
            </Box>
        </Box>
    );
}
