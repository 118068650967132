import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography
} from "@mui/material";
import {grey} from "@mui/material/colors";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import YLDList from "./projectList/YLDList";
import TocaSocialList from "./projectList/TocaSocialList";
import CloudMarginList from "./projectList/CloudMarginList";
import BakerTechList from "./projectList/BakerTechList";
import LadbrokesCoralList from "./projectList/LadbrokesCoralList";
import CambridgeAssessmentList from "./projectList/CambridgeAssessmentList";
import MarksAndSpencerList from "./projectList/MarksAndSpencerList";
import ArgosList from "./projectList/ArgosList";
import KainosList from "./projectList/KainosList";
import WilliamHillList from "./projectList/WilliamHillList";
import OpenBetList from "./projectList/OpenBetList";
export default function CareerHistory() {

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const accordionStyle = {
        backgroundColor: grey[900],
        color: 'white',
        mb: '0.5em',
        width: '100%',
    };

    const summaryStyle = {width: '33%', flexShrink: 0, color: grey[200], fontWeight: 'bold'};

    return (
        <Box sx={{padding: '1em'}}>
            <Typography variant={'h5'} gutterBottom={true} align={'left'} sx={{color: grey[800], fontWeight: 'bold'}}>
                Projects
            </Typography>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={summaryStyle}>
                        YLD - 2023
                    </Typography>
                    <Typography>
                        YLD is a software engineering and design consultancy specialised in software engineering,
                        design, training, and open-source development.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {<YLDList />}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Toca Social - 2022
                    </Typography>
                    <Typography>
                        Toca Football Group is a market leading football experience company based in Los Angeles founded
                        in 2012.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TocaSocialList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Cloud Margin - 2021
                    </Typography>
                    <Typography>
                        Fintech company specialised in collateral management, OTC derivatives, technology, and capital
                        markets.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CloudMarginList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Baker Technology - 2020
                    </Typography>
                    <Typography>
                        Design and delivery of a complex technical solution for a fast-moving Fintech company working on
                        the BrokerTec and EBS migration to Globex platform.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BakerTechList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Ladbrokes Coral - 2019
                    </Typography>
                    <Typography>
                        Ladbrokes Coral is a British betting and gambling company. It was formed from the merger of
                        Ladbrokes and Coral Group.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <LadbrokesCoralList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Cambridge Assessment - 2018
                    </Typography>
                    <Typography>
                        Cambridge Assessment, also known as the University of Cambridge Local Examinations Syndicate, is
                        a non-teaching department of the University of Cambridge.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CambridgeAssessmentList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Marks & Spencer - 2016
                    </Typography>
                    <Typography>
                        Marks & Spencer Group plc is a major British multinational retailer that specializes in selling
                        high quality clothing, home products, and food.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MarksAndSpencerList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel8bh-content"
                    id="panel8bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Argos - 2016
                    </Typography>
                    <Typography>
                        Argos is a British catalogue retailer operating in the United Kingdom and Ireland, and a
                        subsidiary of Sainsbury's
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ArgosList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel9bh-content"
                    id="panel9bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Kainos - 2015
                    </Typography>
                    <Typography>
                        Founded in 1986, Kainos Group plc is a high-growth software company headquartered in Belfast,
                        Northern Ireland.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <KainosList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel10bh-content"
                    id="panel10bh-header"
                >
                    <Typography sx={summaryStyle}>
                        William Hill - 2014
                    </Typography>
                    <Typography>
                        William Hill is one of the world's leading betting and gambling companies, known for their
                        sports betting and gaming services.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <WilliamHillList />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}
                       sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls="panel11bh-content"
                    id="panel11bh-header"
                >
                    <Typography sx={summaryStyle}>
                        Openbet - 2013
                    </Typography>
                    <Typography>
                        OpenBet is a leading software provider for the sports betting and gaming industry.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <OpenBetList />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
