import * as React from 'react';
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {orange} from "@mui/material/colors";

export default function YLDList() {

    const listStyle = {color: orange[700], textAlign: 'center', textColor: 'white', mb: '0.5em'};

    const secondaryTextStyle = {color: 'whitesmoke', mt: '0.1em'};

    return (
        <Box>
            <Typography variant={'h6'} sx={{color: orange[900], textAlign: 'center', textColor: 'white', mb: '0.5em'}}>
                Bulb Energy
            </Typography>
            <Divider color={'white'}/>
            <List>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Designing and Developing Web Applications" secondary=
                        "Designed and developed web applications using Next.js and TypeScript."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Testing and Debugging" secondary=
                        "Designed and developed unit tests using Jest, Pact JS, Cypress to ensure code quality and coverage."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Maintaining Systems" secondary=
                        "Maintained and improved existing systems with Terraform, GCP, and Kubernetes."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Creating and Managing Databases" secondary=
                        "Designed, created, and managed databases using PostgreSQL, MongoDB, and Redis."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Deployment and Configuration Management" secondary=
                        "Created and managed CI/CD pipelines using CircleCI, GitHub Actions, and Jenkins."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Designing and implementing APIs" secondary=
                        "Created APIs using GraphQL, REST, Node JS, GCP BigQuery and Express JS."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
            </List>
        </Box>
    );
};
