import React from 'react';
import './App.css';
import Profile from "./Profile";
import Header from "./Header";
import StickyFooter from "./StickyFooter";
import ProfileBody from "./ProfileBody";
import Summary from "./Summary";
import TechnicalExpertise from "./TechnicalExpertise";
import CareerHistory from "./CareerHistory";

function App() {
    return (
        <div className="App">
            <Profile>
                <Header/>
                <ProfileBody>
                    <Summary/>
                    <TechnicalExpertise/>
                    <CareerHistory/>
                </ProfileBody>
                <StickyFooter/>
            </Profile>
        </div>
    );
}

export default App;
