import * as React from 'react';
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {grey, orange} from "@mui/material/colors";

export default function CloudMarginList() {
    const listStyle = {color: orange[700], textAlign: 'center', textColor: 'white', mb: '0.5em'};

    const secondaryTextStyle = {color: 'whitesmoke', mt: '0.1em'};

    return (
        <Box>
            <Typography variant={'h6'} sx={{color: orange[900], textAlign: 'center', textColor: 'white', mb: '0.5em'}}>
                CloudMargin Collateral Management System
            </Typography>
            <Divider color={'white'}/>
            <List>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Project Understanding and Planning" secondary=
                        "Deep involvement in the planning and design of the project."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Designing and implementing APIs" secondary=
                        "Designed and implemented APIs using Node JS, Express JS, and TypeScript."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Data Modeling and Database Management" secondary=
                        "Designed, created, and managed databases using PostgreSQL and AWS DynomoDB"
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Performance Testing and Optimization" secondary=
                        "Performed performance testing and optimization using JMeter and Gatling."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Maintaining and Deploying Applications" secondary=
                        "Created and managed CI/CD pipelines using CircleCI, GitHub Actions, and Jenkins."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
            </List>
        </Box>
    );
};
