import * as React from 'react';
import {AppBar, Box, styled, Toolbar, Typography} from "@mui/material";
import profileImg from './folarin.jpg'
import {orange} from "@mui/material/colors";

const Item = styled(Typography)(({theme}) => ({
    padding: theme.spacing(2),
    fontFamily: 'monospace',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: '.3rem',
    color: orange[800],
    textDecoration: 'none',
    variant: 'h6',
    textAlign: 'left',
}));

export default function Header() {
    return (
        <AppBar sx={{backgroundColor: 'black'}}>
            <Toolbar disableGutters={true}>
                <Box>
                    <Item
                    >
                        FOLARIN ODEYEMI
                    </Item>
                    <Item>
                        Software Engineering Consultant
                    </Item>
                </Box>
                <img alt={'folarin odeyemi'} src={profileImg} width={75} className={'profile-pic'}/>
            </Toolbar>
        </AppBar>
    );
}
