import * as React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";

export default function ProfileBody({children}: { children: React.ReactNode }) {
    return (
        <Grid2 container spacing={2} sx={{ padding: '0.5em', mt: '1.5em'}}>
            <Grid2 xs={14} md={12}>
                {children}
            </Grid2>
        </Grid2>
    );
}
