import * as React from 'react';
import {Box, Paper, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

export default function Summary() {
    return (
        <Box sx={{
            mt: '7.5em',
            backgroundColor: 'white',
            color: grey[900],
            mb: '0.5em',
        }}
        >
            <Paper elevation={10} sx={{
                padding: '0.5em',
                backgroundColor: 'white',
                color: grey[900],
                mb: '0.5em',
            }}
            >
            <Typography variant="h4" gutterBottom={true} align={'center'} sx={{ color: grey[800], fontWeight: 'bold'}}>
                Professional Summary
            </Typography>
            <Typography variant="body1" gutterBottom={true} align={'left'} sx={{
                padding: '0.5em',
                fontSize: 18,
                display: { xs: 'none', md: 'flex' },
            }}
            >
                Analytical. Solutions-driven. Diligent. A flexible and experienced Senior Software Engineer with a solid
                Front and Back-End engineering background. Adapts rapidly to new technologies and quickly understands
                requirements, building frameworks and developing and enhancing software to significantly improve quality
                and speed. Combines technical innovation with an analytical approach to design and deliver solutions to
                fix multiple performance testing issues on complex projects. A knowledgeable advisor, who works
                cross-functionally, and balances stakeholders demands, remaining calm to achieve deadlines, even under
                intense pressure
            </Typography>
            <Typography variant="body1" gutterBottom={true} align={'center'} sx={{
                padding: '0.5em',
                fontSize: 12,
                display: { xs: 'flex', md: 'none' },
            }}
            >
                Analytical. Solutions-driven. Diligent. A flexible and experienced Senior Software Engineer with a solid
                Front and Back-End engineering background. Adapts rapidly to new technologies and quickly understands
                requirements, building frameworks and developing and enhancing software to significantly improve quality
                and speed. Combines technical innovation with an analytical approach to design and deliver solutions to
                fix multiple performance testing issues on complex projects. A knowledgeable advisor, who works
                cross-functionally, and balances stakeholders demands, remaining calm to achieve deadlines, even under
                intense pressure
            </Typography>
            </Paper>
        </Box>
    )
}
