import * as React from 'react';
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {orange} from "@mui/material/colors";

export default function OpenBetList() {

    const listStyle = {color: orange[700], textAlign: 'center', textColor: 'white', mb: '0.5em'};

    const secondaryTextStyle = {color: 'whitesmoke', mt: '0.1em'};

    return (
        <Box>
            <Typography variant={'h6'} sx={{color: orange[900], textAlign: 'center', textColor: 'white', mb: '0.5em'}}>
                Openbet Sportsbook & Trading Platforms
            </Typography>
            <Divider color={'white'}/>
            <List>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Developing and Maintaining Test Automation Frameworks"
                                  secondary=
                                      "Developed and maintained test automation frameworks using SOAP UI, XML, and Groovy."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Design and Execution of Test Cases" secondary=
                        "Designed and executed test cases for functional, integration, and end-to-end testing."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Bug Reporting and Management" secondary=
                        "Reported and managed bugs using JIRA and TestRail."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Collaboration with Development and Operations Teams" secondary=
                        "Worked closely with Developers to understand software features, with the operations team to understand the infrastructure, and with the business to understand the requirements."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Maintain Testing Documentation" secondary=
                        "Maintained testing documentation such as test plans, test cases, and bug reports."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
            </List>
        </Box>
    );
};
