import * as React from 'react';
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

function createData(area: string, technologies: string) {
    return {area, technologies};
}

const rows = [
    createData('Programming Languages', 'Java, JavaScript, TypeScript, Python, SQL, HTML, CSS, XML, JSON'),
    createData('Frameworks', 'Spring, Hibernate, React, Node.js, Express, Django, Flask'),
    createData('Databases', 'MySQL, Oracle, SQL Server, PostgreSQL, MongoDB, DynamoDB'),
    createData('Testing Tools', 'JUnit, Mockito, Jasmine, Karma, Mocha, Cypress, Playwright, Pact JS, Selenium, JMeter, Gatling, Locust'),
    createData('Build Tools', 'Maven, Gradle, Ant, NPM, Yarn, Webpack, Gulp, Grunt'),
    createData('Version Control', 'Git, SVN, CVS, Perforce, ClearCase'),
    createData('Operating Systems', 'Windows, Linux, macOS'),
    createData('Cloud Platforms', 'AWS, GCP'),
    createData('CI/CD Tools', 'Jenkins, Bamboo, CircleCI, Travis CI, GitHub Actions, ArgoCD, FluxCD, Jenkins X'),
    createData('Monitoring Tools', 'Splunk, Dynatrace, AppDynamics, New Relic, Prometheus, Grafana'),
];

export default function TechnicalExpertise() {
    return (
        <Box sx={{padding: '1em'}}>
            <Typography variant={'h5'} gutterBottom={true} align={'left'} sx={{color: grey[800], fontWeight: 'bold'}}>
                Technical Expertise
            </Typography>
            <TableContainer component={Paper}
                            sx={{
                                backgroundColor: grey[900],
                                color: 'white',
                                mb: '0.5em',
                                width: '100%',
                            }}>
                <Table sx={{minWidth: 650}} size={'small'} aria-label="technical expertise">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color: grey[300], fontWeight: 700, fontSize: 18}} variant={'head'}>Area of
                                Expertise</TableCell>
                            <TableCell sx={{color: grey[300], fontWeight: 700, fontSize: 18}}
                                       variant={'head'}>Technologies</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.area}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row" sx={{color: 'white', fontWeight: 'bold'}}
                                           variant={'body'}>
                                    {row.area}
                                </TableCell>
                                <TableCell sx={{color: 'white', fontWeight: 'bold'}}
                                           variant={'body'}>{row.technologies}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
