import * as React from 'react';
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {orange} from "@mui/material/colors";

export default function CambridgeAssessmentList() {

    const listStyle = {color: orange[700], textAlign: 'center', textColor: 'white', mb: '0.5em'};

    const secondaryTextStyle = {color: 'whitesmoke', mt: '0.1em'};

    return (
        <Box>
            <Typography variant={'h6'} sx={{color: orange[900], textAlign: 'center', textColor: 'white', mb: '0.5em'}}>
                UCLES Result Service
            </Typography>
            <Divider color={'white'}/>
            <List>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Integration of User-Facing Elements" secondary=
                        "Integrated user-facing elements developed by front-end developers with server-side logic."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Server-Side Logic" secondary=
                        "Developed and maintained Java applications using Spring, Hibernate, and MySQL."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Testing and Debugging" secondary=
                        "Tested and debugged Java applications using JUnit, Mockito, MockMVC, and REST Assured."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Database Management" secondary=
                        "Installed, configured, upgraded, and managed MySQL databases."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText sx={listStyle} primary="Collaboration and Communication" secondary=
                        "Collaborated with other team members and stakeholders to gather requirements and provide updates."
                                  secondaryTypographyProps={secondaryTextStyle}/>
                </ListItem>
            </List>
        </Box>
    );
};
